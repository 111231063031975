import React, { Component, Fragment  } from 'react'
import { UserSession, Person } from 'blockstack'
import NavBar from './NavBar'
import User from './User'
import {jsonCopy, remove, add, check, getIndex} from '../assets/utils'
import { appConfig, TASKS_FILENAME, SECURITY_FILENAME, ADDRESS_FILENAME } from '../assets/constants'
import { Button, Card, Divider, Image } from 'semantic-ui-react'
import { Header,Grid, Icon, Segment, Modal, Table, Form, Input, Label, Menu } from 'semantic-ui-react'
import '../styles/Profile.css'
import PinInput from "react-pin-input";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";
import StickyFooter from 'react-sticky-footer';
import CopyToClipboard from 'react-copy-to-clipboard';
import ScrollUpButton from "react-scroll-up-button";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Dropdown } from 'semantic-ui-react'
import { MDBBtn, MDBCollapse } from "mdbreact";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import publicIP from 'react-native-public-ip';
var bigCompanies = require('big-companies');
var lock = (
  <Fragment>
   <p id="pin"> <i aria-hidden="true" className="lock icon"></i> pin protection  </p>

  </Fragment>
);
var unlock = (
  <Fragment>
  <p id="unpin">   <i aria-hidden="true" className="unlock icon"></i> unprotected  </p>
  </Fragment>
)
var star = (
  <Fragment>
  <p> <i aria-hidden="true" className="star icon"></i> </p>
  </Fragment>
)
var unstar = (
  <Fragment>
  <p> <i aria-hidden="true" className="star outline icon"></i> </p>
  </Fragment>
)
var generator = require('generate-password');
let myVar;
class Profile extends Component {
  state = {
  collapseID: ""
}

toggleCollapse = collapseID => () => {
  this.setState(prevState => ({
    collapseID: prevState.collapseID !== collapseID ? collapseID : ""
  }));
}
toggleCollapse2 = collapseID => () => {
  this.setState(prevState => ({
    collapseID: prevState.collapseID !== collapseID ? collapseID : ""
  }));
}
  handleFocus = (event) => event.target.select();
  constructor(props) {
  	super(props);
    this.state = {
       value: ""
     };
     this.state = {
       value: '',
       copied: false,
     };
     this.state = {
      temp: 0,
     };
     this.state = {
      tempPass: 0,
     };
     this.onComplete = (value, index) => {

     };

     this.onClear = () => {
       this.setState({
         value: ""
       });
       this.pin.clear();
     };
  	this.state = {
      tasks: [],
      value: '',
      user: '',
      pass: '',
      pin: '',
      data: '',
      tag: '',
      url: '',
    }
    this.counter = 0;
    this.test = true;
    this.loadTasks = this.loadTasks.bind(this);
    this.handleChangeState = this.handleChangeState.bind(this);
    this.handleChangePass = this.handleChangePass.bind(this);
    this.handleChangeTag = this.handleChangeTag.bind(this);
    this.handleChangeUser = this.handleChangeUser.bind(this);
    this.handleChangeUrl = this.handleChangeUrl.bind(this);
    this.handleChangePin = this.handleChangePin.bind(this);
    this.addTask = this.addTask.bind(this);
    this.removeTask = this.removeTask.bind(this);
    this.checkTask = this.checkTask.bind(this);
    this.show = this.show.bind(this);
    this.showPin = this.showPin.bind(this);
        this.showPinCopy = this.showPinCopy.bind(this);
    this.write = this.write.bind(this);
    this.generateRandomPasswordStrong = this.generateRandomPasswordStrong.bind(this);
        this.generateRandomPasswordMedium= this.generateRandomPasswordMedium.bind(this);
        this.generateRandomPasswordLow= this.generateRandomPasswordLow.bind(this);
              this.generateRandomPasswordCustom= this.generateRandomPasswordCustom.bind(this);
        this.getCounter = this.getCounter.bind(this);
                this.hiddenCard = this.hiddenCard.bind(this);
                          this.hiddenTiles= this.hiddenTiles.bind(this);
                                            this.filter= this.filter.bind(this);
                                              this.filterTable = this.filterTable.bind(this);
                                            this.hideOnStart = this.hideOnStart.bind(this);
                                                this.sortTable = this.sortTable.bind(this);
                                                      this.setNumber = this.setNumber.bind(this);
                                                      this.getNumber = this.getNumber.bind(this);
                                                      this.setNumberPass = this.setNumber.bind(this);
                                                      this.getNumberPass = this.getNumber.bind(this);
                                                      this.labels = this.labels.bind(this);
                                                      this.labelsCard = this.labelsCard.bind(this);
                                                      this.toggleButton = this.toggleButton.bind(this);
                                                        this.slider = this.slider.bind(this);
                                                              this.getAddress = this.getAddress.bind(this);

                                                              this.addAddress = this.addAddress.bind(this);
                                                              this.closeSecurity = this.closeSecurity.bind(this);
                                                              this.randomNumber = this.randomNumber.bind(this);
                                                              this.getIps = this.getIps.bind(this);
                                                                              this.setSecurity = this.setSecurity.bind(this);
                                                                                  this.proofSecurity = this.proofSecurity.bind(this);
                                                                                  this.logout2 = this.logout2.bind(this);
                                                                                  this.stopTimeout = this.stopTimeout.bind(this);
                                                                                  this.closeModal = this.closeModal.bind(this);
                                                                                  this.checkRecovery = this.checkRecovery.bind(this);
                                                                                  this.clearAddressLog = this.clearAddressLog.bind(this);
                                                                                  this.openLog = this.openLog.bind(this);
                                                                                  this.getlog = this.getlog.bind(this);

  }

  componentWillMount() {
    this.loadTasks();
  }
  componentDidMount() {
               //Hide Card on Start
               this.hideOnStart();
               //proof Security
               // var dummy = [];
               // dummy.push(["129.2192.2", "19.02.1994"]);
               // const options = { decrypt: true };
               // this.props.userSession.putFile(SECURITY_FILENAME, JSON.stringify(dummy), options);
               this.proofSecurity();

               this.getAddress();
               //Hide Sidebar on start
               this.toggleButton();
  }
  randomNumber() {
    var number = parseInt(Math.random() * (10000000000000 - 0) + 0);
    return number;
  }
  openLog() {
    this.handleOpen5();
    this.addAddress();
    this.getlog();
  }
  checkRecovery(value) {
    const options = { decrypt: true };
    this.props.userSession.getFile(SECURITY_FILENAME, options)
    .then((content) => {
      if(content) {
        const tasks = JSON.parse(content);
        //
        publicIP()
        .then(ip => {
              if(parseInt(value) == tasks[0][1]) {
                this.handleClose7();
                document.getElementById("overallScreen").style.display = "block";
              } else {
                this.props.userSession.signUserOut();
                window.location.reload();
              }
        });
      }
    }).catch(error => {
        //console.log("error on proof");
        // 'Unable to get IP address.'
      });
  }
  closeSecurity() {
    const options = { decrypt: true };
    this.props.userSession.deleteFile(SECURITY_FILENAME, options)
  }
  getIps() {
    publicIP()
    .then(ip => {
      document.getElementById("myIp").value = ip;
      })
    .catch(error => {
      //console.log("error on Ip");
      // 'Unable to get IP address.'
    });
  }
  closeModal() {
    this.handleClose6();
  }
  setSecurity() {
    const options = { decrypt: true };
    var tasks = [];
    var recovery = document.getElementById("randomNum").innerHTML;
    var ip = document.getElementById("myIp").value;
    tasks.push([ip, recovery]);
    console.log(recovery);
    this.props.userSession.putFile(SECURITY_FILENAME, JSON.stringify(tasks), options);
    NotificationManager.info("Security Mode activated");
  }
  slider() {
    var slider = document.getElementById("myRange");
    switch(slider.value) {
    case "1":
      // code block
          this.generateRandomPasswordCustom();
                document.getElementById("passwordLabel").innerHTML = "Password: Weak";
                    NotificationManager.info("Created Weak Password");
      break;
    case "2":
      // code block
          this.generateRandomPasswordCustom();
                document.getElementById("passwordLabel").innerHTML = "Password: Medium";
                    NotificationManager.info("Created Medium Password");
      break;
      case "3":
        // code block
          this.generateRandomPasswordCustom();
          document.getElementById("passwordLabel").innerHTML = "Password: Strong";
              NotificationManager.info("Created Strong Password");
        break;
        case "4":
          // code block
            this.generateRandomPasswordCustom();
            document.getElementById("passwordLabel").innerHTML = "Password: Stronger ";
                NotificationManager.info("Created Stronger Password");
          break;
    default:
      this.generateRandomPasswordCustom();
      document.getElementById("passwordLabel").innerHTML = "Password: Strongest ";
        NotificationManager.info("Created Strongest Password");
      // code block
  }
  }
  componentWillReceiveProps(nextProps) {
    const nextTasks = nextProps.tasks;
    if(nextTasks) {
      if (nextTasks.length !== this.state.tasks.length) {
        this.setState({ tasks: jsonCopy(nextTasks) });
      }
    }
  }
  setNumber(i) {
    this.state.temp = i;
  }
  getNumber() {
    return this.state.temp;
  }
  setNumberPass(i) {
    this.state.tempPass = i;
  }
  getNumberPass() {
    return this.state.tempPass;
  }
  //clear filter
  clearAddressLog() {
    const options = { decrypt: true };
    this.props.userSession.deleteFile(ADDRESS_FILENAME, options);
    NotificationManager.info("Historic Connection Log cleared");
    document.getElementById("logs").innerHTML = "";
  }
  addAddress() {
  publicIP()
  .then(ip => {
    const options = { decrypt: true };
    this.props.userSession.getFile(ADDRESS_FILENAME, options)
    .then((content) => {
      var today = new Date();
      var dayWeek = today.getDate() < 10 ? "0" + today.getDate(): today.getDate();
      var date = dayWeek +'-'+ (today.getMonth()+1) +'-'+ today.getFullYear();
      var sec = today.getSeconds() < 10 ? "0" + today.getSeconds(): today.getSeconds();
      var min = today.getMinutes() < 10 ? "0" + today.getMinutes(): today.getMinutes();
      var hours = today.getHours() < 10 ? "0" + today.getHours(): today.getHours();
      var time = hours + ":" + min + ":" + sec;
      var dateTime = date+' '+time;

      if(content) {
        const tasks = JSON.parse(content);
        var protocol = window.location.protocol + "//passwordstack.de";
        tasks.push([ip, dateTime, protocol, window.location.protocol]);
        if(tasks.length > 70) {
          tasks.shift();
        }
        this.props.userSession.putFile(ADDRESS_FILENAME, JSON.stringify(tasks), options);
      }
      //File doesnt exist
      else {
        var tasks = [];
        var protocol = window.location.protocol + "//passwordstack.de";
        tasks.push([ip, dateTime, protocol, window.location.protocol]);
        this.props.userSession.putFile(ADDRESS_FILENAME, JSON.stringify(tasks), options);
      }
    }).catch(error => {
        //console.log("error add Address");
        // 'Unable to get IP address.'
      });
  })
  .catch(error => {
    //console.log(error);
    // 'Unable to get IP address.'
  });
  }
  logout2() {
  this.props.userSession.signUserOut();
  window.location.reload();
  }
  proofSecurity() {
    const options = { decrypt: true };
    this.props.userSession.getFile(SECURITY_FILENAME, options)
    .then((content) => {
      if(content) {
        const tasks = JSON.parse(content);
        publicIP()
        .then(ip => {
              if(ip !== tasks[0][0]) {
                document.getElementById("overallScreen").style.display = "none";
                //Routes;
                // if(window.location.pathname !== "/security") {
                //     window.location.pathname = "/security";
                // }
                this.handleOpen7();

              }
        });
      } else {

      }
    }).catch(error => {
        //console.log("error on proof");
        // 'Unable to get IP address.'

      });
  }
  stopTimeout() {
    var x = document.getElementById("showTable");
    x.style.display = "inline-table";
  }
  getAddress() {
    publicIP()
    .then(ip => {
      document.getElementById("publicIp").innerHTML = "connected from <b>IPv4</b> " + ip;
      //document.getElementById("lower").innerHTML = "<b>IPv4 </b> " + ip + " /Connection Logs"
      })
    .catch(error => {
      //console.log(error);
      // 'Unable to get IP address.'
    });
  }
  getlog() {
    const options = { decrypt: true };
    this.props.userSession.getFile(ADDRESS_FILENAME, options)
    .then((content) => {
      if(content) {
        const tasks = JSON.parse(content);
        var html = "<table id='full' class='ui celled table'><thead class=''><tr class=''><th class='Protocol'>Protocol</th><th class='Status'>Status</th><th class='IPv4'>IPv4</th><th class='Date'>Date</th></tr></thead> <tbody class=''>";
        for(var i = 0; i < tasks.length; i++) {
          if(tasks[i][0]) {
            console.log(tasks[i][3]);
          html = html + "<tr>" + "<td> " + tasks[i][2] + "</td>" + "<td> " + (tasks[i][3] !== "http:" ? "was secure over SSL" : "<i className='warning sign icon'/> no secure login") + "</td>" + "<td>" + tasks[i][0] + "</td><td><p>"+ tasks[i][1] + "</p></td> </div></div></tr>";
          }

        }
        html = html + " </tbody></table>";
              document.getElementById("logs").innerHTML = html;

      }
      //File doesnt exist
      else {
        document.getElementById("logs").innerHTML = "<div class='ui warning message'>  <div class='content'>  <div class='header2'>No logs found</p>  </div></div>";
      }
  })
}
  labelsCard(a) {
    return (
      <div>
      <i aria-hidden="true" className="tag icon"></i> <a class="ui image label"> {a} </a>
      </div>
    );
  }
  labels(a) {
    return (
      <div>
      <a class="ui image label"> {a} </a>
      </div>
    );
  }
  loadTasks() {
    const options = { decrypt: true };
    this.props.userSession.getFile(TASKS_FILENAME, options)
    .then((content) => {
      if(content) {
        const tasks = JSON.parse(content);
        this.setState({tasks});

            //If Empty show Image
            if(tasks.length == 0) {
              document.getElementById("showTable").style.display = "none"
            }
      }
    })
  }
  toggleButton() {
    document.getElementById("sidebar-wrapper").style.width = "0";
    document.getElementById("sidebar-wrapper").style.marginLeft = "0";
    document.getElementById("wrapper").style.paddingLeft = "50px";
  }
  generateRandomPasswordCustom() {
    var slider = document.getElementById("myRange");
    var multiple = slider.value
    var passwords = generator.generateMultiple(multiple, {
    length: 15,
    uppercase: document.getElementById("uppercases").checked ,
    numbers: document.getElementById("numbers").checked,
    symbols: document.getElementById("symbols").checked,
    });
    document.getElementById("password").value = passwords;
        this.state.pass = passwords;

  }
  generateRandomPasswordLow() {
    var passwords = generator.generateMultiple(2, {
    length: 5,
    uppercase: false,
    numbers: true,
    symbols: false,
    });
    document.getElementById("password").value = passwords;
        var slider = document.getElementById("myRange");
        slider.value = 1;
        this.state.pass = passwords;
        NotificationManager.info("Created Weak Password");
  }
  generateRandomPasswordMedium() {
    var passwords = generator.generateMultiple(2, {
    length: 12,
    uppercase: true,
    numbers: true,
    symbols: false,
    exclude: ",."
    });
    document.getElementById("password").value = passwords;
    var slider = document.getElementById("myRange");
    slider.value = 2;
    this.state.pass = passwords;
      NotificationManager.info("Created Medium Password");
  }
  getCounter() {
    const options = { decrypt: true };
    var notCrypt = 0;
    var crypt = 0;
    this.props.userSession.getFile(TASKS_FILENAME, options)
    .then((content) => {
      document.getElementById("total").innerHTML = "0";
      document.getElementById("score").innerHTML = "0";
      if(content) {
        const tasks = JSON.parse(content);
        for(var i = 0; i < tasks.length; i++) {

                    tasks[i][5] === "" ? notCrypt = notCrypt + 1 : crypt = crypt + 1;
        }
                document.getElementById("total").innerHTML = tasks.length;
                if(tasks.length < 1) {
                  document.getElementById("score").innerHTML = "0"
                } else {
                  document.getElementById("score").innerHTML = parseInt(crypt / tasks.length * 100);
                }

      }
        document.getElementById("Uncrypt").innerHTML = notCrypt;
        document.getElementById("crypt").innerHTML = crypt;



    })


  }
  sortTable() {
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("showTable");
    switching = true;
    /* Make a loop that will continue until
    no switching has been done: */
    while (switching) {
      // Start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /* Loop through all table rows (except the
      first, which contains table headers): */
      for (i = 1; i < (rows.length - 1); i++) {
        // Start by saying there should be no switching:
        shouldSwitch = false;
        /* Get the two elements you want to compare,
        one from current row and one from the next: */
        x = rows[i].getElementsByTagName("TD")[1];
        y = rows[i + 1].getElementsByTagName("TD")[1];
        // Check if the two rows should switch place:
        if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
          // If so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      }
      if (shouldSwitch) {
        /* If a switch has been marked, make the switch
        and mark that a switch has been done: */
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    }
  }
  generateRandomPasswordStrong() {
    var passwords = generator.generateMultiple(3, {
    length: 15,
    uppercase: true,
    numbers: true,
    symbols: true,
    exclude: ",."

    });
    document.getElementById("password").value = passwords;
    var slider = document.getElementById("myRange");
    slider.value = 3;
        this.state.pass = passwords;
        NotificationManager.info("Created Strong Password");
  }
  saveTasks(tasks) {
    const options = { encrypt: true };
    this.props.userSession.putFile(TASKS_FILENAME, JSON.stringify(tasks), options);
  }
  hideOnStart() {
     var y = document.getElementById("table");
     y.style.display = "none";
  }
  handleChangeState(event) {
    this.setState({value: event.target.value});
   }
  handleChangePass(event) {
     this.setState({pass: event.target.value});
   }
   handleChangeTag(event) {
      var str = event.target.value;
      var res = str.split(" ");
      this.setState({tag: res});
    }
  handleChangeUser(event) {
      this.setState({user: event.target.value});
   }
   handleChangePin(event) {
       this.setState({pin: event.target.value});
    }
   handleChangeUrl(event) {
     var url = event.target.value.replace(/(^\w+:|^)\/\//, '');
      this.setState({url: url});
    }
    hiddenCard() {
      var x = document.getElementById("table");
      var y = document.getElementById("showTable");

       if (x.style.display === "none") {
         x.style.display = "block"
         document.getElementById("tiles").disabled = true;
         document.getElementById("tables").disabled = false;
         y.style.display = "none";
       } else {
         x.style.display = "none";

       }
    }
    hiddenTiles() {
      var x = document.getElementById("showTable");
            var y = document.getElementById("table");

       if (x.style.display === "none") {
         x.style.display =  "inline-table";
         document.getElementById("tables").disabled = true;
         document.getElementById("tiles").disabled = false;
         y.style.display = "none";
       } else {
         x.style.display = "none";


       }
    }
  removeTask(e) {
    var index = e.currentTarget.dataset.index;
    if(typeof e.currentTarget.dataset.index === "undefined") {

    } else {
      e.preventDefault();
      var isProtected = false;
      const options = { decrypt: true };
      this.props.userSession.getFile(TASKS_FILENAME, options)
      .then((content) => {
        if(content) {
          const tasks = JSON.parse(content);
          for(var i = 0; i < tasks.length; i++) {

                  if(index == i) {
                      isProtected = tasks[i][5] === "" ? false : true;
                      if(!isProtected) {
                      const tasks = remove(index, this.state);
                      this.setState({ tasks });
                      this.saveTasks(tasks);
                      NotificationManager.success("PasswordStack deleted");

                    } else {
                       this.handleOpen2();
                        this.setNumber(index);
                    }
                  }
          }
        }
      })

    }
  }
  //Delete a Protected Stack
  write(pin, index) {
    var isProtected = false;
    index = this.getNumberPass();
    const options = { decrypt: true };
    this.props.userSession.getFile(TASKS_FILENAME, options)
    .then((content) => {
      if(content) {
        const tasks = JSON.parse(content);
        for(var i = 0; i < tasks.length; i++) {

                if(index == i) {
                    isProtected = tasks[i][5] == pin ? true : false;
                    if(isProtected) {
                    const tasks = remove(index, this.state);
                    this.setState({ tasks });
                    this.saveTasks(tasks);
                    this.handleClose2();
                    NotificationManager.success("PasswordStack deleted");
                  } else {
                    NotificationManager.error("Wrong Pin-Code");
                    this.onClear();
                  }
                }
        }
      }
    })
  }
  addTask(e) {
    e.preventDefault();
    const tasks = add(this.state);
    this.setState({value: '', tasks});
    this.saveTasks(tasks);
    NotificationManager.success("PasswordStack created");
    //Clear Field
    this.state.pass = "";
    document.getElementById("showTable").style.display = "inline-table"
  }
  show(e) {
    e.preventDefault();
    var number = e.currentTarget.dataset.index;
    var list = document.getElementsByClassName("show")[number];
    var list2 = document.getElementsByClassName("show2")[number];
    if(typeof list === "undefined") {

    } else {
      var isProtected = false;
      const options = { decrypt: true };
      this.props.userSession.getFile(TASKS_FILENAME, options)
      .then((content) => {
        if(content) {
          const tasks = JSON.parse(content);
          for(var i = 0; i < tasks.length; i++) {

                  if(number == i) {
                      isProtected = tasks[i][5] === "" ? false : true;
                      if(!isProtected) {
                        list.type = "text";
                        list2.type = "text";
                        setTimeout(function() {
                        // Code, der erst nach 2 Sekunden ausgeführt wird
                            list = document.getElementsByClassName("show")[number];
                            list2 = document.getElementsByClassName("show2")[number];
                            list.type = "password";
                            list2.type = "password";

                        }, 10000);
                          NotificationManager.info("Password visible for 10 Seconds");

                    } else {
                       this.handleOpen3();
                       this.setNumber(number);
                    }
                  }
          }
        }
      })

    }

  }
  filter(check) {
  // Declare variables
  var input, filter, table, tr, td, i, txtValue;
  if(check == 0) {
    if(document.getElementById("filterInput2").checked) {
      input = "yes favourite";
      filter = input.toUpperCase();
    } else {
      input = "";
      filter = input.toUpperCase();
    }
  } else {
    input = document.getElementById("filterInput");
    filter = input.value.toUpperCase();
  }
  table = document.getElementById("table");
  tr = table.getElementsByTagName("tr");

  // Loop through all table rows, and hide those who don't match the search query
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[0];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
}
filterTable(check) {
  // Declare variables
    var input, filter, table, tr, td, i, occurrence;
    if(check == 0) {
      if(document.getElementById("filterInput2").checked) {
        input = "yes favourite";
        filter = input.toUpperCase();
      } else {
        input = "";
        filter = input.toUpperCase();
      }
    } else {
      input = document.getElementById("filterInput");
      filter = input.value.toUpperCase();
    }

    table = document.getElementById("showTable");
    tr = table.getElementsByTagName("tr");

    // Loop through all table rows, and hide those who don't match the search query
   for (i = 0; i < tr.length; i++) {
       occurrence = false; // Only reset to false once per row.
       td = tr[i].getElementsByTagName("td");
       for(var j=0; j< td.length; j++){
           var currentTd = td[j];
           if (currentTd ) {
               if (currentTd.innerHTML.toUpperCase().indexOf(filter) > -1) {
                   tr[i].style.display = "";
                   occurrence = true;
               }
           }
       }
       if(!occurrence){
           tr[i].style.display = "none";
       }
   }
}
  showPin(pin, index) {
    var number = this.getNumber();
    var list = document.getElementsByClassName("show")[number];
    var list2 = document.getElementsByClassName("show2")[number];
    if(typeof list === "undefined") {

    } else {
      var isProtected = false;
      const options = { decrypt: true };
      this.props.userSession.getFile(TASKS_FILENAME, options)
      .then((content) => {
        if(content) {
          const tasks = JSON.parse(content);
          for(var i = 0; i < tasks.length; i++) {

                  if(number == i) {
                      isProtected = tasks[i][5] == pin ? true : false;
                      if(isProtected) {
                        list.value = tasks[i][2];
                        list2.value = tasks[i][2];
                        list.type = "text";
                        list2.type = "text";
                        this.handleClose3();
                        setTimeout(function() {
                        // Code, der erst nach 2 Sekunden ausgeführt wird
                            list.type = "password";
                                list2.type = "password";


                        }, 10000);
                        NotificationManager.info("Password visible for 10 Seconds");

                    } else {
                      NotificationManager.error("Wrong Pin-Code");
                      this.onClear();

                    }
                  }
          }
        }
      })

    }

  }
  showPinCopy(pin, index) {
    var number = this.getNumber();
    if(typeof number === "undefined") {

    } else {
      var isProtected = false;
      const options = { decrypt: true };
      this.props.userSession.getFile(TASKS_FILENAME, options)
      .then((content) => {
        if(content) {
          const tasks = JSON.parse(content);
          for(var i = 0; i < tasks.length; i++) {

                  if(number == i) {
                      isProtected = tasks[i][5] == pin ? true : false;
                      if(isProtected) {
                        this.handleClose3();

                    } else {
                      NotificationManager.error("Wrong Pin-Code");
                      this.onClear();

                    }
                  }
          }
        }
      })

    }

  }

  checkTask(e) {
    const tasks = check(e.target.dataset.index, this.state);
    this.setState({ tasks });
    this.saveTasks(tasks);
  }
  state = { modalOpen: false, modalOpen: false }
  handleClick = () => this.setState(prevState => ({ active: !prevState.active }))

handleOpen = () => this.setState({ modalOpen: true })

handleClose = () => this.setState({ modalOpen: false })

handleOpen2 = () => this.setState({ modalOpen2: true })

handleClose2 = () => this.setState({ modalOpen2: false })

handleOpen3 = () => this.setState({ modalOpen3: true })

handleClose3 = () => this.setState({ modalOpen3: false })

handleOpen4 = () => this.setState({ modalOpen4: true })

handleClose4 = () => this.setState({ modalOpen4: false })

handleOpen5 = () => this.setState({ modalOpen5: true })

handleClose5 = () => this.setState({ modalOpen5: false })

handleOpen6 = () => this.setState({ modalOpen6: true })

handleClose6 = () => this.setState({ modalOpen6: false })

handleOpen7 = () => this.setState({ modalOpen7: true })

handleClose7 = () => this.setState({ modalOpen7: false })
  render() {
    const username = this.props.userSession.loadUserData().username;
    const profile = this.props.userSession.loadUserData();
    const person = new Person(profile);
    const { active } = this.state
    return (

<div>
<Route exact path="/security" component={User} exact />


<div id="overallScreen">
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
    <nav className="navbar navbar-default no-margin">
        {/* Navbar */}
          <NavBar username={username} user={person} signOut={this.props.handleSignOut}/>
      {/* navbar-header*/}
      <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
        <ul className="nav navbar-nav">
          <li className="active">
            <button className="navbar-toggle collapse in" data-toggle="collapse" id="menu-toggle-2"> <span className="glyphicon glyphicon-th-large" aria-hidden="true" />
            </button>
          </li>
        </ul>
      </div>
      {/* bs-example-navbar-collapse-1 */}
    </nav>
    <div id="wrapper">
      {/* Sidebar */}

      <div id="sidebar-wrapper">
        <ul className="sidebar-nav nav-pills nav-stacked" id="menu">
        <li>
          <div className="ui horizontal divider">Menu</div>
          </li>
          <li>

          </li>
          <li id="li2">
          <Button color='teal' content='Create new Stack' icon='add' labelPosition='left' onClick={this.handleOpen} />
        <div className="ui horizontal divider">Or</div>

          </li>
          <li id="li2">


                  <input id="filterInput2" type="checkbox" readonly="" tabindex="0" onClick={e => {  this.filterTable(0); this.filter(0)}}/>
                  <label className="star icon"><p> show only <i aria-hidden="true" className="star icon"></i> </p></label>
          </li>
          <li id="li2">
          </li>
          <li id="li2">
          <div className="ui horizontal divider">Or</div>
                  <div className="ui action left icon input">

                    <input onChange={e => {  this.filterTable(e); this.filter(e)}}  id="filterInput" type="text" placeholder="Search Stack" />
                    <i aria-hidden="true" className="search icon"></i>
                  </div>
          </li>
          <li id="li2">
              <div className="ui horizontal divider">Support</div>
          <a href="https://www.buymeacoffee.com/GAPQV8dv3" target="_blank">
          <img id="img3" src="https://bmc-cdn.nyc3.digitaloceanspaces.com/BMC-button-images/custom_images/white_img.png" alt="Buy Me A Coffee" />
          </a>
          </li>
          <li id="li2">
              <div className="ui horizontal divider">Ecosystem</div>
          <a href="https://docs.blockstack.org/" target="_blank">
          <img id="img4" src="/Blockstack.png" alt="Blockstack" />
          </a>
          </li>
        </ul>
      </div>
      {/* /#sidebar-wrapper */}
      {/* Page Content */}
      <div id="page-content-wrapper">
        <div className="container-fluid xyz">
          <div className="row">
          <div id="dashboard" className="Dashboard">
          <div>
                  <ScrollUpButton />
              </div>
          <div className="ui placeholder segment">
      <div className="ui icon header">
      <img src='./avatar-placeholder2.png' />
      <AnimateKeyframes
        play
        direction= 'normal'
         duration={3}
        keyframes={["opacity: 0", "opacity: 1"]}
      >
        <h1>Your Decentralized Password Vault</h1><br></br>
      </AnimateKeyframes>

      </div>
      <Modal
            trigger={<Button color='teal' content='Create new Stack' icon='add' labelPosition='left' onClick={this.handleOpen} />}
            open={this.state.modalOpen}
            onClose={this.handleClose}
            size='small'>
        <Modal.Content image>
        <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
        <div id="generatorsModal">
        <div className="ui buttons">
        {/*
        <a id="low" onClick={this.generateRandomPasswordLow} className="ui  button"> Low </a>
        <a id="medium" onClick={this.generateRandomPasswordMedium} className="ui  button"> Medium </a>
        <a id="strong" onClick={this.generateRandomPasswordStrong} className="ui  button">  Strong </a>
        */}
         </div>

         <div id="whiteContent">

        <a id="closer"  onClick={this.toggleCollapse2("basicCollapse")}>   <button id="cloesWindow" class="ui icon left labeled button">
    <i aria-hidden="true" class="check icon"></i> Add Password & Close

  </button>  </a>
          <div class="card-header text-center font-weight-bold text-uppercase py-4">
          <input type="range" min="1" max="5" class="slider" onChange={this.slider} id="myRange"/>
          </div> </div>

          <table id="forGenerator" class="ui celled definition compact table">



  <tbody class="">
    <tr class="">
      <td class="collapsing">
        <div class="ui fitted slider checkbox">
          <input id="uppercases" type="checkbox" class="" readonly="" tabindex="0" />
          <label></label>
        </div>
      </td>
      <td class="">Uppercase</td>


    </tr>
    <tr class="">
      <td class="collapsing">
        <div class="ui fitted slider checkbox">
          <input id="numbers" type="checkbox" class="" readonly="" tabindex="0" />
          <label></label>
        </div>
      </td>
      <td class="">Numbers</td>


    </tr>
    <tr class="">
      <td class="collapsing">
        <div class="ui fitted slider checkbox">
          <input id="symbols" type="checkbox" class="" readonly="" tabindex="0" />
          <label></label>
        </div>
      </td>
      <td class="">Symbols</td>


    </tr>
  </tbody>
  <tfoot class="full-width">
    <tr class="">
      <th class="">
      </th>
      <th colspan="4" class="">
        <button id="randomText" onClick={this.generateRandomPasswordCustom} class="ui small icon primary right floated left labeled button">
          <i aria-hidden="true" class="text width icon"></i>
          Random
        </button>
      </th>
    </tr>
  </tfoot>
</table>
        <div id="demo"></div>
        </div>
        </MDBCollapse>
          <Modal.Description>
          <div id="as" class="ui menu">
<a id="asItem" onClick={this.handleClose} class="item"> <img src='./avatar-placeholder2.png' width = "50" height="50"/> </a>
<a id="asItem" onClick={this.handleClose} class="item"><i className="close icon"/> Close Window</a>
<a id="asItem" onClick={this.toggleCollapse("basicCollapse")} class="item"> <i className="random icon"/> Password Generator</a>


</div>
            <Header></Header>

              <form onSubmit={this.addTask} className="ui form">

            <table id="forms" class="ui celled table">

  <thead class="">
<td class="card-header text-center font-weight-bold text-uppercase py-4">
Required
</td>
<td class="card-header text-center font-weight-bold text-uppercase py-4">

</td>
  </thead>
  <tbody class="">
    <tr class="">
      <td id="boltMe" class="">Application</td>
      <td class="">
      <div>

        <input id="otherInput" required maxLength="30" List="languages" className="form-control" type="text" placeholder="Required" onChange={this.handleChangeState.bind(this)} value={this.state.value} required />

        <datalist id="languages">
          <option value={bigCompanies[0].shortNames[0]}></option>
          <option value={bigCompanies[1].shortNames[0]}></option>
          <option value={bigCompanies[2].shortNames[0]}></option>
          <option value={bigCompanies[3].shortNames[0]}></option>
          <option value={bigCompanies[4].shortNames[0]}></option>
          <option value={bigCompanies[5].shortNames[0]}></option>
          <option value={bigCompanies[6].shortNames[0]}></option>
          <option value={bigCompanies[7].shortNames[0]}></option>
          <option value={bigCompanies[8].shortNames[0]}></option>
          <option value={bigCompanies[9].shortNames[0]}></option>
          <option value={bigCompanies[10].shortNames[0]}></option>
          <option value={bigCompanies[11].shortNames[0]}></option>
          <option value={bigCompanies[12].shortNames[0]}></option>
          <option value={bigCompanies[13].shortNames[0]}></option>
          <option value={bigCompanies[14].shortNames[0]}></option>
          <option value={bigCompanies[15].shortNames[0]}></option>
          <option value={bigCompanies[16].shortNames[0]}></option>
          <option value={bigCompanies[17].shortNames[0]}></option>
          <option value={bigCompanies[18].shortNames[0]}></option>
          <option value={bigCompanies[19].shortNames[0]}></option>
          <option value={bigCompanies[20].shortNames[0]}></option>
          <option value={bigCompanies[21].shortNames[0]}></option>
          <option value={bigCompanies[22].shortNames[0]}></option>
          <option value={bigCompanies[23].shortNames[0]}></option>
          <option value={bigCompanies[24].shortNames[0]}></option>
          <option value={bigCompanies[25].shortNames[0]}></option>
          <option value={bigCompanies[26].shortNames[0]}></option>
          <option value={bigCompanies[27].shortNames[0]}></option>
          <option value={bigCompanies[28].shortNames[0]}></option>
          <option value={bigCompanies[29].shortNames[0]}></option>
          <option value={bigCompanies[30].shortNames[0]}></option>
          <option value={bigCompanies[31].shortNames[0]}></option>
          <option value={bigCompanies[32].shortNames[0]}></option>
          <option value={bigCompanies[33].shortNames[0]}></option>
          <option value={bigCompanies[34].shortNames[0]}></option>
          <option value={bigCompanies[35].shortNames[0]}></option>
          <option value={bigCompanies[38].shortNames[0]}></option>
          <option value={bigCompanies[37].shortNames[0]}></option>
          <option value={bigCompanies[38].shortNames[0]}></option>
          <option value={bigCompanies[39].shortNames[0]}></option>
          <option value={bigCompanies[40].shortNames[0]}></option>
          <option value={bigCompanies[41].shortNames[0]}></option>
          <option value={bigCompanies[42].shortNames[0]}></option>
          <option value={bigCompanies[43].shortNames[0]}></option>
          <option value={bigCompanies[44].shortNames[0]}></option>
          <option value={bigCompanies[45].shortNames[0]}></option>
          <option value={bigCompanies[46].shortNames[0]}></option>
          <option value={bigCompanies[47].shortNames[0]}></option>
          <option value={bigCompanies[48].shortNames[0]}></option>
          <option value={bigCompanies[49].shortNames[0]}></option>
          <option value={bigCompanies[50].shortNames[0]}></option>
          <option value={bigCompanies[60].shortNames[0]}></option>
          <option value={bigCompanies[61].shortNames[0]}></option>
          <option value={bigCompanies[62].shortNames[0]}></option>
          <option value={bigCompanies[63].shortNames[0]}></option>
          <option value={bigCompanies[64].shortNames[0]}></option>
          <option value={bigCompanies[65].shortNames[0]}></option>
          <option value={bigCompanies[66].shortNames[0]}></option>
          <option value={bigCompanies[67].shortNames[0]}></option>
          <option value={bigCompanies[68].shortNames[0]}></option>
          <option value={bigCompanies[69].shortNames[0]}></option>
          <option value={bigCompanies[70].shortNames[0]}></option>
          <option value={bigCompanies[70].shortNames[0]}></option>
          <option value={bigCompanies[71].shortNames[0]}></option>
          <option value={bigCompanies[72].shortNames[0]}></option>
          <option value={bigCompanies[73].shortNames[0]}></option>
          <option value={bigCompanies[74].shortNames[0]}></option>
          <option value={bigCompanies[75].shortNames[0]}></option>
          <option value={bigCompanies[77].shortNames[0]}></option>
          <option value={bigCompanies[77].shortNames[0]}></option>
          <option value={bigCompanies[78].shortNames[0]}></option>
          <option value={bigCompanies[79].shortNames[0]}></option>
          <option value={bigCompanies[80].shortNames[0]}></option>
          <option value={bigCompanies[81].shortNames[0]}></option>
          <option value={bigCompanies[82].shortNames[0]}></option>
          <option value={bigCompanies[83].shortNames[0]}></option>
          <option value={bigCompanies[84].shortNames[0]}></option>
          <option value={bigCompanies[85].shortNames[0]}></option>
          <option value={bigCompanies[88].shortNames[0]}></option>
          <option value={bigCompanies[87].shortNames[0]}></option>
          <option value={bigCompanies[88].shortNames[0]}></option>
          <option value={bigCompanies[89].shortNames[0]}></option>
          <option value={bigCompanies[90].shortNames[0]}></option>
          <option value={bigCompanies[91].shortNames[0]}></option>
          <option value={bigCompanies[92].shortNames[0]}></option>
          <option value={bigCompanies[93].shortNames[0]}></option>
          <option value={bigCompanies[94].shortNames[0]}></option>
          <option value={bigCompanies[95].shortNames[0]}></option>
          <option value={bigCompanies[99].shortNames[0]}></option>
          <option value={bigCompanies[97].shortNames[0]}></option>
          <option value={bigCompanies[98].shortNames[0]}></option>
          <option value={bigCompanies[99].shortNames[0]}></option>
          <option value={bigCompanies[100].shortNames[0]}></option>
          <option value={bigCompanies[101].shortNames[0]}></option>
          <option value={bigCompanies[102].shortNames[0]}></option>
          <option value={bigCompanies[103].shortNames[0]}></option>
          <option value={bigCompanies[104].shortNames[0]}></option>
          <option value={bigCompanies[105].shortNames[0]}></option>
          <option value={bigCompanies[108].shortNames[0]}></option>
          <option value={bigCompanies[107].shortNames[0]}></option>
          <option value={bigCompanies[108].shortNames[0]}></option>
          <option value={bigCompanies[109].shortNames[0]}></option>
          <option value={bigCompanies[110].shortNames[0]}></option>
          <option value={bigCompanies[111].shortNames[0]}></option>
          <option value={bigCompanies[112].shortNames[0]}></option>
          <option value={bigCompanies[113].shortNames[0]}></option>
          <option value={bigCompanies[114].shortNames[0]}></option>
          <option value={bigCompanies[115].shortNames[0]}></option>
          <option value={bigCompanies[111].shortNames[0]}></option>
          <option value={bigCompanies[117].shortNames[0]}></option>
          <option value={bigCompanies[118].shortNames[0]}></option>
          <option value={bigCompanies[119].shortNames[0]}></option>
          <option value={bigCompanies[120].shortNames[0]}></option>
          <option value={bigCompanies[121].shortNames[0]}></option>
          <option value={bigCompanies[122].shortNames[0]}></option>
          <option value={bigCompanies[123].shortNames[0]}></option>
          <option value={bigCompanies[124].shortNames[0]}></option>
          <option value={bigCompanies[125].shortNames[0]}></option>
          <option value={bigCompanies[112].shortNames[0]}></option>
          <option value={bigCompanies[127].shortNames[0]}></option>
          <option value={bigCompanies[128].shortNames[0]}></option>
          <option value={bigCompanies[129].shortNames[0]}></option>
          <option value={bigCompanies[130].shortNames[0]}></option>
          <option value={bigCompanies[131].shortNames[0]}></option>
          <option value={bigCompanies[132].shortNames[0]}></option>
          <option value={bigCompanies[133].shortNames[0]}></option>
          <option value={bigCompanies[134].shortNames[0]}></option>
          <option value={bigCompanies[135].shortNames[0]}></option>
          <option value={bigCompanies[136].shortNames[0]}></option>
          <option value={bigCompanies[137].shortNames[0]}></option>
          <option value={bigCompanies[138].shortNames[0]}></option>
          <option value={bigCompanies[139].shortNames[0]}></option>
          <option value={bigCompanies[140].shortNames[0]}></option>
          <option value={bigCompanies[141].shortNames[0]}></option>
          <option value={bigCompanies[142].shortNames[0]}></option>
          <option value={bigCompanies[143].shortNames[0]}></option>
          <option value={bigCompanies[144].shortNames[0]}></option>
          <option value={bigCompanies[145].shortNames[0]}></option>
          <option value={bigCompanies[146].shortNames[0]}></option>
          <option value={bigCompanies[147].shortNames[0]}></option>
          <option value={bigCompanies[148].shortNames[0]}></option>
          <option value={bigCompanies[149].shortNames[0]}></option>
          <option value={bigCompanies[150].shortNames[0]}></option>
          <option value={bigCompanies[151].shortNames[0]}></option>
          <option value={bigCompanies[152].shortNames[0]}></option>
          <option value={bigCompanies[153].shortNames[0]}></option>
          <option value={bigCompanies[154].shortNames[0]}></option>
          <option value={bigCompanies[155].shortNames[0]}></option>
          <option value={bigCompanies[156].shortNames[0]}></option>
          <option value={bigCompanies[157].shortNames[0]}></option>
          <option value={bigCompanies[158].shortNames[0]}></option>
          <option value={bigCompanies[159].shortNames[0]}></option>
          <option value={bigCompanies[160].shortNames[0]}></option>
          <option value={bigCompanies[161].shortNames[0]}></option>
          <option value={bigCompanies[162].shortNames[0]}></option>
          <option value={bigCompanies[163].shortNames[0]}></option>
          <option value={bigCompanies[164].shortNames[0]}></option>
          <option value={bigCompanies[165].shortNames[0]}></option>
          <option value={bigCompanies[168].shortNames[0]}></option>
          <option value={bigCompanies[167].shortNames[0]}></option>
          <option value={bigCompanies[168].shortNames[0]}></option>
          <option value={bigCompanies[169].shortNames[0]}></option>
          <option value={bigCompanies[170].shortNames[0]}></option>
          <option value={bigCompanies[171].shortNames[0]}></option>
          <option value={bigCompanies[172].shortNames[0]}></option>
          <option value={bigCompanies[173].shortNames[0]}></option>
          <option value={bigCompanies[174].shortNames[0]}></option>
          <option value={bigCompanies[175].shortNames[0]}></option>
          <option value={bigCompanies[176].shortNames[0]}></option>
          <option value={bigCompanies[177].shortNames[0]}></option>
          <option value={bigCompanies[178].shortNames[0]}></option>
          <option value={bigCompanies[179].shortNames[0]}></option>
          <option value={bigCompanies[180].shortNames[0]}></option>
          <option value={bigCompanies[181].shortNames[0]}></option>
          <option value={bigCompanies[182].shortNames[0]}></option>
          <option value={bigCompanies[183].shortNames[0]}></option>
          <option value={bigCompanies[184].shortNames[0]}></option>
          <option value={bigCompanies[185].shortNames[0]}></option>
          <option value={bigCompanies[186].shortNames[0]}></option>
          <option value={bigCompanies[187].shortNames[0]}></option>
          <option value={bigCompanies[188].shortNames[0]}></option>
          <option value={bigCompanies[189].shortNames[0]}></option>
          <option value={bigCompanies[190].shortNames[0]}></option>
          <option value={bigCompanies[191].shortNames[0]}></option>
          <option value={bigCompanies[192].shortNames[0]}></option>
          <option value={bigCompanies[193].shortNames[0]}></option>
          <option value={bigCompanies[194].shortNames[0]}></option>
          <option value={bigCompanies[195].shortNames[0]}></option>
          <option value={bigCompanies[196].shortNames[0]}></option>
          <option value={bigCompanies[197].shortNames[0]}></option>
          <option value={bigCompanies[198].shortNames[0]}></option>
          <option value={bigCompanies[199].shortNames[0]}></option>
          <option value={bigCompanies[200].shortNames[0]}></option>
          <option value={bigCompanies[201].shortNames[0]}></option>
          <option value={bigCompanies[202].shortNames[0]}></option>
          <option value={bigCompanies[203].shortNames[0]}></option>
          <option value={bigCompanies[204].shortNames[0]}></option>
          <option value={bigCompanies[205].shortNames[0]}></option>
          <option value={bigCompanies[208].shortNames[0]}></option>
          <option value={bigCompanies[207].shortNames[0]}></option>
          <option value={bigCompanies[208].shortNames[0]}></option>
          <option value={bigCompanies[209].shortNames[0]}></option>
          <option value={bigCompanies[210].shortNames[0]}></option>
          <option value={bigCompanies[211].shortNames[0]}></option>
          <option value={bigCompanies[212].shortNames[0]}></option>
          <option value={bigCompanies[213].shortNames[0]}></option>
          <option value={bigCompanies[214].shortNames[0]}></option>
          <option value={bigCompanies[215].shortNames[0]}></option>
          <option value={bigCompanies[211].shortNames[0]}></option>
          <option value={bigCompanies[217].shortNames[0]}></option>
          <option value={bigCompanies[218].shortNames[0]}></option>
          <option value={bigCompanies[219].shortNames[0]}></option>
          <option value={bigCompanies[220].shortNames[0]}></option>
          <option value={bigCompanies[221].shortNames[0]}></option>
          <option value={bigCompanies[222].shortNames[0]}></option>
          <option value={bigCompanies[223].shortNames[0]}></option>
          <option value={bigCompanies[224].shortNames[0]}></option>
          <option value={bigCompanies[225].shortNames[0]}></option>
          <option value={bigCompanies[212].shortNames[0]}></option>
          <option value={bigCompanies[227].shortNames[0]}></option>
          <option value={bigCompanies[228].shortNames[0]}></option>
          <option value={bigCompanies[229].shortNames[0]}></option>
          <option value={bigCompanies[230].shortNames[0]}></option>
          <option value={bigCompanies[231].shortNames[0]}></option>
          <option value={bigCompanies[232].shortNames[0]}></option>
          <option value={bigCompanies[233].shortNames[0]}></option>
          <option value={bigCompanies[234].shortNames[0]}></option>
          <option value={bigCompanies[235].shortNames[0]}></option>
          <option value={bigCompanies[236].shortNames[0]}></option>
          <option value={bigCompanies[237].shortNames[0]}></option>
          <option value={bigCompanies[238].shortNames[0]}></option>
          <option value={bigCompanies[239].shortNames[0]}></option>
          <option value={bigCompanies[240].shortNames[0]}></option>
          <option value={bigCompanies[241].shortNames[0]}></option>
          <option value={bigCompanies[242].shortNames[0]}></option>
          <option value={bigCompanies[243].shortNames[0]}></option>
          <option value={bigCompanies[244].shortNames[0]}></option>
          <option value={bigCompanies[245].shortNames[0]}></option>
          <option value={bigCompanies[246].shortNames[0]}></option>
          <option value={bigCompanies[247].shortNames[0]}></option>
          <option value={bigCompanies[248].shortNames[0]}></option>
          <option value={bigCompanies[249].shortNames[0]}></option>
          <option value={bigCompanies[250].shortNames[0]}></option>
          <option value={bigCompanies[251].shortNames[0]}></option>
          <option value={bigCompanies[252].shortNames[0]}></option>
          <option value={bigCompanies[253].shortNames[0]}></option>
          <option value={bigCompanies[254].shortNames[0]}></option>
          <option value={bigCompanies[255].shortNames[0]}></option>
          <option value={bigCompanies[256].shortNames[0]}></option>
          <option value={bigCompanies[257].shortNames[0]}></option>
          <option value={bigCompanies[258].shortNames[0]}></option>
          <option value={bigCompanies[259].shortNames[0]}></option>
          <option value={bigCompanies[260].shortNames[0]}></option>
          <option value={bigCompanies[261].shortNames[0]}></option>
          <option value={bigCompanies[262].shortNames[0]}></option>
          <option value={bigCompanies[263].shortNames[0]}></option>
          <option value={bigCompanies[264].shortNames[0]}></option>
          <option value={bigCompanies[265].shortNames[0]}></option>
          <option value={bigCompanies[268].shortNames[0]}></option>
          <option value={bigCompanies[267].shortNames[0]}></option>
          <option value={bigCompanies[268].shortNames[0]}></option>
          <option value={bigCompanies[269].shortNames[0]}></option>
          <option value={bigCompanies[270].shortNames[0]}></option>
          <option value={bigCompanies[271].shortNames[0]}></option>
          <option value={bigCompanies[272].shortNames[0]}></option>
          <option value={bigCompanies[273].shortNames[0]}></option>
          <option value={bigCompanies[274].shortNames[0]}></option>
          <option value={bigCompanies[275].shortNames[0]}></option>
          <option value={bigCompanies[276].shortNames[0]}></option>
          <option value={bigCompanies[277].shortNames[0]}></option>
          <option value={bigCompanies[278].shortNames[0]}></option>
          <option value={bigCompanies[279].shortNames[0]}></option>
          <option value={bigCompanies[280].shortNames[0]}></option>
          <option value={bigCompanies[281].shortNames[0]}></option>
          <option value={bigCompanies[282].shortNames[0]}></option>
          <option value={bigCompanies[283].shortNames[0]}></option>
          <option value={bigCompanies[284].shortNames[0]}></option>
          <option value={bigCompanies[285].shortNames[0]}></option>
          <option value={bigCompanies[286].shortNames[0]}></option>
          <option value={bigCompanies[287].shortNames[0]}></option>
          <option value={bigCompanies[288].shortNames[0]}></option>
          <option value={bigCompanies[289].shortNames[0]}></option>
          <option value={bigCompanies[290].shortNames[0]}></option>
          <option value={bigCompanies[291].shortNames[0]}></option>
          <option value={bigCompanies[292].shortNames[0]}></option>
          <option value={bigCompanies[293].shortNames[0]}></option>
          <option value={bigCompanies[294].shortNames[0]}></option>
          <option value={bigCompanies[295].shortNames[0]}></option>
          <option value={bigCompanies[296].shortNames[0]}></option>
          <option value={bigCompanies[297].shortNames[0]}></option>
          <option value={bigCompanies[298].shortNames[0]}></option>
          <option value={bigCompanies[299].shortNames[0]}></option>
          <option value={bigCompanies[300].shortNames[0]}></option>
          <option value={bigCompanies[301].shortNames[0]}></option>
          <option value={bigCompanies[302].shortNames[0]}></option>
          <option value={bigCompanies[303].shortNames[0]}></option>
          <option value={bigCompanies[304].shortNames[0]}></option>
          <option value={bigCompanies[305].shortNames[0]}></option>
          <option value={bigCompanies[308].shortNames[0]}></option>
          <option value={bigCompanies[307].shortNames[0]}></option>
          <option value={bigCompanies[308].shortNames[0]}></option>
          <option value={bigCompanies[309].shortNames[0]}></option>
          <option value={bigCompanies[310].shortNames[0]}></option>
          <option value={bigCompanies[311].shortNames[0]}></option>
          <option value={bigCompanies[312].shortNames[0]}></option>
          <option value={bigCompanies[313].shortNames[0]}></option>
          <option value={bigCompanies[314].shortNames[0]}></option>
          <option value={bigCompanies[315].shortNames[0]}></option>
          <option value={bigCompanies[311].shortNames[0]}></option>
          <option value={bigCompanies[317].shortNames[0]}></option>
          <option value={bigCompanies[318].shortNames[0]}></option>
          <option value={bigCompanies[319].shortNames[0]}></option>
          <option value={bigCompanies[320].shortNames[0]}></option>
          <option value={bigCompanies[321].shortNames[0]}></option>
          <option value={bigCompanies[322].shortNames[0]}></option>
          <option value={bigCompanies[323].shortNames[0]}></option>
          <option value={bigCompanies[324].shortNames[0]}></option>
          <option value={bigCompanies[325].shortNames[0]}></option>
          <option value={bigCompanies[312].shortNames[0]}></option>
          <option value={bigCompanies[327].shortNames[0]}></option>
          <option value={bigCompanies[328].shortNames[0]}></option>
          <option value={bigCompanies[329].shortNames[0]}></option>
          <option value={bigCompanies[330].shortNames[0]}></option>
          <option value={bigCompanies[331].shortNames[0]}></option>
          <option value={bigCompanies[332].shortNames[0]}></option>
          <option value={bigCompanies[333].shortNames[0]}></option>
          <option value={bigCompanies[334].shortNames[0]}></option>
          <option value={bigCompanies[335].shortNames[0]}></option>
          <option value={bigCompanies[336].shortNames[0]}></option>
          <option value={bigCompanies[337].shortNames[0]}></option>
          <option value={bigCompanies[338].shortNames[0]}></option>
          <option value={bigCompanies[339].shortNames[0]}></option>
          <option value={bigCompanies[340].shortNames[0]}></option>
          <option value={bigCompanies[341].shortNames[0]}></option>
          <option value={bigCompanies[342].shortNames[0]}></option>
          <option value={bigCompanies[343].shortNames[0]}></option>
          <option value={bigCompanies[344].shortNames[0]}></option>
          <option value={bigCompanies[345].shortNames[0]}></option>
          <option value={bigCompanies[346].shortNames[0]}></option>
          <option value={bigCompanies[347].shortNames[0]}></option>
          <option value={bigCompanies[348].shortNames[0]}></option>
          <option value={bigCompanies[349].shortNames[0]}></option>
          <option value={bigCompanies[350].shortNames[0]}></option>
          <option value={bigCompanies[351].shortNames[0]}></option>
          <option value={bigCompanies[352].shortNames[0]}></option>
          <option value={bigCompanies[353].shortNames[0]}></option>
          <option value={bigCompanies[354].shortNames[0]}></option>
          <option value={bigCompanies[355].shortNames[0]}></option>
          <option value={bigCompanies[356].shortNames[0]}></option>
          <option value={bigCompanies[357].shortNames[0]}></option>
          <option value={bigCompanies[358].shortNames[0]}></option>
          <option value={bigCompanies[359].shortNames[0]}></option>
          <option value={bigCompanies[360].shortNames[0]}></option>
          <option value={bigCompanies[361].shortNames[0]}></option>
          <option value={bigCompanies[362].shortNames[0]}></option>
          <option value={bigCompanies[363].shortNames[0]}></option>
          <option value={bigCompanies[364].shortNames[0]}></option>
          <option value={bigCompanies[365].shortNames[0]}></option>
          <option value={bigCompanies[368].shortNames[0]}></option>
          <option value={bigCompanies[367].shortNames[0]}></option>
          <option value={bigCompanies[368].shortNames[0]}></option>
          <option value={bigCompanies[369].shortNames[0]}></option>
          <option value={bigCompanies[370].shortNames[0]}></option>
          <option value={bigCompanies[371].shortNames[0]}></option>
          <option value={bigCompanies[372].shortNames[0]}></option>
          <option value={bigCompanies[373].shortNames[0]}></option>
          <option value={bigCompanies[374].shortNames[0]}></option>
          <option value={bigCompanies[375].shortNames[0]}></option>
          <option value={bigCompanies[376].shortNames[0]}></option>
          <option value={bigCompanies[377].shortNames[0]}></option>
          <option value={bigCompanies[378].shortNames[0]}></option>
          <option value={bigCompanies[379].shortNames[0]}></option>
          <option value={bigCompanies[380].shortNames[0]}></option>
          <option value={bigCompanies[381].shortNames[0]}></option>
          <option value={bigCompanies[382].shortNames[0]}></option>
          <option value={bigCompanies[383].shortNames[0]}></option>
          <option value={bigCompanies[384].shortNames[0]}></option>
          <option value={bigCompanies[385].shortNames[0]}></option>
          <option value={bigCompanies[386].shortNames[0]}></option>
          <option value={bigCompanies[387].shortNames[0]}></option>
          <option value={bigCompanies[388].shortNames[0]}></option>
          <option value={bigCompanies[389].shortNames[0]}></option>
          <option value={bigCompanies[390].shortNames[0]}></option>
          <option value={bigCompanies[391].shortNames[0]}></option>
          <option value={bigCompanies[392].shortNames[0]}></option>
          <option value={bigCompanies[393].shortNames[0]}></option>
          <option value={bigCompanies[394].shortNames[0]}></option>
          <option value={bigCompanies[395].shortNames[0]}></option>
          <option value={bigCompanies[396].shortNames[0]}></option>
          <option value={bigCompanies[397].shortNames[0]}></option>
          <option value={bigCompanies[398].shortNames[0]}></option>
          <option value={bigCompanies[399].shortNames[0]}></option>
          <option value={bigCompanies[400].shortNames[0]}></option>
          <option value={bigCompanies[401].shortNames[0]}></option>
          <option value={bigCompanies[402].shortNames[0]}></option>
          <option value={bigCompanies[403].shortNames[0]}></option>
          <option value={bigCompanies[404].shortNames[0]}></option>
          <option value={bigCompanies[405].shortNames[0]}></option>
          <option value={bigCompanies[408].shortNames[0]}></option>
          <option value={bigCompanies[407].shortNames[0]}></option>
          <option value={bigCompanies[408].shortNames[0]}></option>
          <option value={bigCompanies[409].shortNames[0]}></option>
          <option value={bigCompanies[410].shortNames[0]}></option>
          <option value={bigCompanies[411].shortNames[0]}></option>
          <option value={bigCompanies[412].shortNames[0]}></option>
          <option value={bigCompanies[413].shortNames[0]}></option>
          <option value={bigCompanies[414].shortNames[0]}></option>
          <option value={bigCompanies[415].shortNames[0]}></option>
          <option value={bigCompanies[411].shortNames[0]}></option>
          <option value={bigCompanies[417].shortNames[0]}></option>
          <option value={bigCompanies[418].shortNames[0]}></option>
          <option value={bigCompanies[419].shortNames[0]}></option>
          <option value={bigCompanies[420].shortNames[0]}></option>
          <option value={bigCompanies[421].shortNames[0]}></option>
          <option value={bigCompanies[422].shortNames[0]}></option>
          <option value={bigCompanies[423].shortNames[0]}></option>
          <option value={bigCompanies[424].shortNames[0]}></option>
          <option value={bigCompanies[425].shortNames[0]}></option>
          <option value={bigCompanies[412].shortNames[0]}></option>
          <option value={bigCompanies[427].shortNames[0]}></option>
          <option value={bigCompanies[428].shortNames[0]}></option>
          <option value={bigCompanies[429].shortNames[0]}></option>
          <option value={bigCompanies[430].shortNames[0]}></option>
          <option value={bigCompanies[431].shortNames[0]}></option>
          <option value={bigCompanies[432].shortNames[0]}></option>
          <option value={bigCompanies[433].shortNames[0]}></option>
          <option value={bigCompanies[434].shortNames[0]}></option>
          <option value={bigCompanies[435].shortNames[0]}></option>
          <option value={bigCompanies[436].shortNames[0]}></option>
          <option value={bigCompanies[437].shortNames[0]}></option>
          <option value={bigCompanies[438].shortNames[0]}></option>
          <option value={bigCompanies[439].shortNames[0]}></option>
          <option value={bigCompanies[440].shortNames[0]}></option>
          <option value={bigCompanies[441].shortNames[0]}></option>
          <option value={bigCompanies[442].shortNames[0]}></option>
          <option value={bigCompanies[443].shortNames[0]}></option>
          <option value={bigCompanies[444].shortNames[0]}></option>
          <option value={bigCompanies[445].shortNames[0]}></option>
          <option value={bigCompanies[446].shortNames[0]}></option>
          <option value={bigCompanies[447].shortNames[0]}></option>
          <option value={bigCompanies[448].shortNames[0]}></option>
          <option value={bigCompanies[449].shortNames[0]}></option>
          <option value={bigCompanies[450].shortNames[0]}></option>
          <option value={bigCompanies[451].shortNames[0]}></option>
          <option value={bigCompanies[452].shortNames[0]}></option>
          <option value={bigCompanies[453].shortNames[0]}></option>
          <option value={bigCompanies[454].shortNames[0]}></option>
          <option value={bigCompanies[455].shortNames[0]}></option>
          <option value={bigCompanies[456].shortNames[0]}></option>
          <option value={bigCompanies[457].shortNames[0]}></option>
          <option value={bigCompanies[458].shortNames[0]}></option>
          <option value={bigCompanies[459].shortNames[0]}></option>
          <option value={bigCompanies[460].shortNames[0]}></option>
          <option value={bigCompanies[461].shortNames[0]}></option>
          <option value={bigCompanies[462].shortNames[0]}></option>
          <option value={bigCompanies[463].shortNames[0]}></option>
          <option value={bigCompanies[464].shortNames[0]}></option>
          <option value={bigCompanies[465].shortNames[0]}></option>
          <option value={bigCompanies[468].shortNames[0]}></option>
          <option value={bigCompanies[467].shortNames[0]}></option>
          <option value={bigCompanies[468].shortNames[0]}></option>
          <option value={bigCompanies[469].shortNames[0]}></option>
          <option value={bigCompanies[470].shortNames[0]}></option>
          <option value={bigCompanies[471].shortNames[0]}></option>
          <option value={bigCompanies[472].shortNames[0]}></option>
          <option value={bigCompanies[473].shortNames[0]}></option>
          <option value={bigCompanies[474].shortNames[0]}></option>
          <option value={bigCompanies[475].shortNames[0]}></option>
          <option value={bigCompanies[476].shortNames[0]}></option>
          <option value={bigCompanies[477].shortNames[0]}></option>
          <option value={bigCompanies[478].shortNames[0]}></option>
          <option value={bigCompanies[479].shortNames[0]}></option>
          <option value={bigCompanies[480].shortNames[0]}></option>
          <option value={bigCompanies[481].shortNames[0]}></option>
          <option value={bigCompanies[482].shortNames[0]}></option>
          <option value={bigCompanies[483].shortNames[0]}></option>
          <option value={bigCompanies[484].shortNames[0]}></option>
          <option value={bigCompanies[485].shortNames[0]}></option>
          <option value={bigCompanies[486].shortNames[0]}></option>
          <option value={bigCompanies[487].shortNames[0]}></option>
          <option value={bigCompanies[488].shortNames[0]}></option>
          <option value={bigCompanies[489].shortNames[0]}></option>
          <option value={bigCompanies[490].shortNames[0]}></option>
          <option value={bigCompanies[491].shortNames[0]}></option>
          <option value={bigCompanies[492].shortNames[0]}></option>
          <option value={bigCompanies[493].shortNames[0]}></option>
          <option value={bigCompanies[494].shortNames[0]}></option>
          <option value={bigCompanies[495].shortNames[0]}></option>
          <option value={bigCompanies[496].shortNames[0]}></option>
          <option value={bigCompanies[497].shortNames[0]}></option>
          <option value={bigCompanies[498].shortNames[0]}></option>
          <option value={bigCompanies[499].shortNames[0]}></option>
          <option value={bigCompanies[499].shortNames[0]}></option>
          <option value="reddit"></option>
          <option value="ebay"></option>
          <option value="kicker"></option>
          <option value="blockstack"></option>
          <option value="origin"></option>
          <option value="steampowered"></option>
          <option value="hermes"></option>
          <option value="dhl"></option>
        </datalist>
      </div>
   </td>
    </tr>
    <tr class="">
      <td id="boltMe" class="">Username</td>
      <td class=""><input id="otherInput" required maxLength="30" className="form-control" type="text" placeholder="Required" onChange={this.handleChangeUser.bind(this)} value={this.state.user} /></td>
    </tr>
    <tr class="">
      <td id="boltMe" class=""><p id="passwordLabel">    Password </p>
    <div className="ui horizontal divider">           </div>
      </td>

      <td class="">
      <input id="password" autocomplete="off" className="form-control" type="text" placeholder="Required" onChange={this.handleChangePass.bind(this)}  required  maxLength="150" value={this.state.pass} />
      </td>

    </tr>

    <td class="card-header text-center font-weight-bold text-uppercase py-4">
    Optional
    </td>
    <td class="card-header text-center font-weight-bold text-uppercase py-4">

    </td>
    <tr class="">
      <td id="boltMe" class="">Website</td>
      <td class="">
      <div id="witdthMe" >        <div class="ui labeled input">
        <div class="ui label label">https://</div>
        <input  id="extras"  maxLength="50" className="form-control" type="text" placeholder="Optional" onChange={this.handleChangeUrl.bind(this)} value={this.state.url} />
      </div></div> </td>
    </tr>
    <tr class="">

      <td id="boltMe" class="">Tags</td>
      <td class="">  <input id="otherInput"    maxLength="30" className="form-control" type="text" placeholder="Optional" onChange={this.handleChangeTag.bind(this)} value={this.state.tag} /></td>
    </tr>
    <tr class="">
      <td id="boltMe" class="">Pin </td>
      <td class="">  <input id="otherInput"  className="form-control" minLength="4"  type="text" placeholder="Optional" onChange={this.handleChangePin.bind(this)} maxLength="4" value={this.state.pin} /> </td>
    </tr>
    <tr class="">
      <td class="">       <button id="Gener2" class="ui icon right labeled button" onClick={this.handleClick}  active={active}> Create Stack  <i aria-hidden="true" class="upload icon"></i> </button>
 </td>
      <td id="notice" class="negative selectable">   <input type="checkbox" id="scales" name="scales" required /> I understand that my pin can not be recovered </td>
    </tr>
    <td class="card-header text-center font-weight-bold text-uppercase py-6">

    </td>
    <td class="card-header text-center font-weight-bold text-uppercase py-6">

    </td>
  </tbody>
</table>


</form>

          </Modal.Description>
        </Modal.Content>
      </Modal>
      <Modal  id="modals"
              open={this.state.modalOpen2}
              onClose={this.handleClose2}
              basic
              size='small'
            >

              <Header id="status2" icon='browser' content='Pin Protection' />
              <Modal.Content>
                  <Input id="pincode" placeholder='Enter Pin-Code...' />
                 <Button  onClick={ this.write} content='Confirm' />
              </Modal.Content>
              <Modal.Actions>
                <Button color='green' onClick={this.handleClose2} inverted>
                  <Icon name='checkmark' /> Got it
                </Button>
              </Modal.Actions>
            </Modal>
         <NotificationContainer/>
    </div>

          <link rel="stylesheet" href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css" />
          <NavBar username={username} user={person} signOut={this.props.handleSignOut}/>

            <div className="row justify-content-center"id="header">
            <Menu stackable>
              <Menu.Item>
                <img src='./avatar-placeholder2.png' />
              </Menu.Item>

              <Menu.Item
                name='features'

              >

              <Modal   id="modals2"
                      open={this.state.modalOpen5}
                      onClose={this.handleClose5}
                      basic
                      size='small'>
                      <div id="as" class="ui menu">

  <a id="asItem" onClick={this.handleClose5} class="item"> <img src='./avatar-placeholder2.png' width = "50" height="50"/> </a>
  <a id="asItem" onClick={this.handleClose5} class="item"><i className="close icon"/> Close Window</a>
  <a id="asItem" onClick={this.clearAddressLog} class="item"> <i className="book icon"/> Clear Log</a>


</div>



              <Modal.Content image id="logsContent">
                    <div id="logs"> </div>
              </Modal.Content>
              </Modal>

              <Modal   id="modals7"
                      open={this.state.modalOpen6}
                      onClose={this.handleClose6}
                      basic
                      size='small'>
                      <div id="as" class="ui menu">

  <a id="asItem" onClick={this.closeModal} class="item"> <img src='./avatar-placeholder2.png' width = "50" height="50"/> </a>
  <a id="asItem" onClick={this.closeModal} class="item"><i className="close icon"/> Close Window</a>
  <a id="asItem" onClick={this.setSecurity} class="item"> <i className="send icon"/> Send Confirmation </a>
    <a id="asItem" onClick={this.closeSecurity} class="item"> <i className="user delete icon"/> Disable Security Mode </a>


</div>


              <div>
                <div id="IsoMode" class="ui justified container">
                  <div id="confirmation">                 <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                              </div> Waiting for Confirmation </div>
                  <div id="dividerCss" class="ui divider"></div>
                  <div id="textContainers">
                  <p>
                  <b> What is security mode? </b> <br></br> <br></br>

                  Here you have the choice to decide who access this vault.
                  If you choose this option, the vault will be locked and only be available for your current or custom IPv4-Address.
                  To perform a dynamic IPv4-Address change, please write down the recovery code.
                  This allows you to re-enter the vault if the IPv4-Address has been changed.
                  </p>
                  <div id="isolation"></div>

                  <p> Recovery Code </p> <b> <p id="randomNum"> {this.randomNumber()} </p> </b> </div> <br></br>
                <div class="ui as labeled input">
                <div class="ui label label">IPv4</div>
                <input type="text"  id="myIp" value={this.getIps()} placeholder=" " />

                </div>

                </div>

              </div>

              </Modal>

              <Modal   id="modals6"
                      open={this.state.modalOpen7}
                      onClose={this.handleClose7}
                      basic
                      closeOnDimmerClick={false}
                      size='small'>
                      <div id="mobileMenu" class="ui menu">
  <a class="item"><img src='./avatar-placeholder2.png' height="50" width="50"/></a>
  <a id="sec" class="item"><i className="key icon"/> Security Mode</a>
  <a id="sec" class="item" onClick={this.logout2}><i aria-hidden="true" class="dont icon"></i>  Cant Remember</a>
</div>
              <Modal.Content id="contentIso">
 <h3 class="ui block header2">
            <p> The current IPv4 doesn't match to the specified IPv4 in Security Mode. Please enter the recovery code to continue and update the address or disable the Security Mode.  </p>
             <div class="spinner-border" role="status">
                         <span class="sr-only">Loading...</span>
                         </div> Recovery Code</h3>
              <PinInput id="modals"
                        length={13}
                        focus = {true}
                        secret = {true}
                        ref={p => (this.pin = p)}
                        type="numeric"
                        onChange={this.onChange}
                        onComplete={(value) => {this.checkRecovery(value)}}
                      />
                      <div id="sendStatus">  </div>

              </Modal.Content>
            </Modal>





              <Dropdown item text={username} simple>
              <Dropdown.Menu>
                <Dropdown.Item disabled={true} id="no2"><div id="publicIp"> <i className="wifi icon"> </i> </div></Dropdown.Item>
                <Dropdown.Item id="tops2"><i className="book icon"> </i> <a onClick={this.openLog} > Show Logfile </a></Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item id="tops3"><i className="user close icon"> </i> <a onClick={this.handleOpen6} > Security Mode </a></Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item id="tops"><i className="power off icon"> </i> <a onClick={this.props.handleSignOut}> Logout </a></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
              </Menu.Item>

              <Menu.Item
                name='testimonials'

              >
              <Icon name='block layout' /> total stacks
              <Label color='teal' floating>
              {this.getCounter()}
                <div id="total"></div>

              </Label>
              </Menu.Item>

              <Menu.Item
                name='sign-in'

              >
              <Icon name='lock' /> protection
              <Label color='green' floating>
              <div id="crypt"></div>
              </Label>
              </Menu.Item>
              <Menu.Item
                name='testimonials'

              >
              <Icon name='unlock' /> unprotected
              <Label color='red' floating>
                  <div id="Uncrypt"></div>
              </Label>
              </Menu.Item>
              <Menu.Item
                name='testimonials'

              >
              <Icon name='point' /> safety score
              <Label color='purple' floating>
                  <div id="score"></div>
              </Label>
              </Menu.Item>
            </Menu>



              <h3 className="user-info">

              </h3>
            </div>
            {/* Start of Table*/}
            <table id="showTable" className="ui celled definition compact table">

      <thead class="ui stackable table">
          <th className=""><i className="star icon"/></th>
          <th className="">Application</th>
          <th className="">Website</th>
          <th className="">User</th>
          <th className="">Password</th>
          <th className="">Protection</th>
          <th className="">Tags</th>
          <th className="">Modified</th>
          <th className=""></th>

      </thead>
      <tbody>
        {this.state.tasks.map((task, i) => {
          return [
              <tr key={i}>
              <td>      <div id="created8" className="ui fitted slider checkbox">
      <input type="checkbox" readonly="" tabindex="0" data-index={i} onClick={this.checkTask} defaultChecked={task[3]? true : false} />
      <label></label>
    </div>  <label>{task[3]? star : unstar}</label><label hidden>{task[3]? "yes favourite" : "no favourite"}</label></td>
                <td>
    <div className="meta"id="created3"><img  className="meta" id="" width="30" height="30" src={'//logo.clearbit.com/' + task[0] + '.com '} alt=" "/>  {task[0]}</div>
                </td>
                <td>
    <div className="meta"id="created7"> <a target="_blank" href={ typeof task[8] === "undefined" ? "#" :  'https://' + task[8] } class="ui image label"> https://{task[8]}</a></div>
                </td>
                <td><div className="meta"id="created3">{task[1]}</div></td>
                <td><div id="widthTable" className="ui action input"><input className="show" type="password"  id="passMe" readOnly="readOnly" defaultValue={task[5] == "" ? task[2]: task[6]  } onFocus={this.handleFocus}/></div><button data-index={i} onClick={task[5] == "" ? this.show : this.show } className="ui icon button"><i aria-hidden="true" className="key icon"></i></button>
                </td>
                <td id="pro"><div className="meta"id="created3" >{task[5] == "" ? unlock  : lock}</div></td>
                  <td><div className="meta"id="created3"> {task[7] == "" ? "" : this.labelsCard(task[7])} </div></td>
                <td><div className="meta"id="created3">{task[4]}</div></td>
                <td>      <button id="editDelete2" className="ui teal button" data-index={i} onClick={task[5] == "" ? this.removeTask  : this.removeTask}>
                        <div className="X" data-index={i}><i aria-hidden="true" className="trash alternate icon"></i></div>
                      </button></td>

                {/* <td>
                      <button id="editDelete2" className="ui button" data-index={i} onClick={this.removeTask}>
                        <div className="X" data-index={i}><i aria-hidden="true" className="edit icon"></i> Edit Stack</div>
                      </button>
                      </td>
                      */}

              </tr>
          ];
        })}
      </tbody>
         </table>
            <div id="table" className="row justify-content-center">
              <div className="row">
                {this.state.tasks.map((task, i) =>

                  <tr key={i}>
    <td>
                    <div className="row">

                      <div className="col">


                        <div className="ui doubling stackable cards">

                          <div className="ui card">

                            <div className="content">

                              <div className="header" id="created">

                              <Grid.Column>

        <Label id="colors"  attached='top'>
        <Label color='white' image>
      <img src='./avatar-placeholder3.png' />
      {i}
      <Label.Detail>{task[0]} </Label.Detail>

    </Label>     <label>{task[3]? star : unstar}</label><label hidden>{task[3]? "yes favourite" : "no favourite"}</label>
        <div id="createdStar" className="ui fitted slider checkbox">
        <input type="checkbox" readonly="" tabindex="0" data-index={i} onClick={this.checkTask} defaultChecked={task[3]? true : false} />
        <label></label>
        </div> </Label>

    <hr></hr>
    </Grid.Column>
                     </div>
    <br></br>
                              <div className="meta" id="created"><div class="ui mini right floated image"><i aria-hidden="true" className="user icon"></i></div> {task[1]}</div><hr></hr>
                              <div className="meta" id="created"><div class="ui mini right floated image"><i aria-hidden="true" className="linkify icon"></i></div> <a target="_blank" href={ typeof task[8] === "undefined" ? "#" :  'https://' + task[8] } >https://{task[8]}</a> </div><hr></hr>




                              <div className="meta" id="created" ><div className="ui focus input">
                              <input className="show2" type="password"  readOnly="readOnly" defaultValue={task[5] == "" ? task[2]: task[6] } onFocus={this.handleFocus}/>


                              </div></div>
                              <div className="ui buttons">
                      <button data-index={i} onClick={task[5] == "" ? this.show : this.show } className="ui icon button"><i aria-hidden="true"></i>{task[5] == "" ? unlock  : lock}</button>

      </div> <hr></hr>
                              <div className="meta" id="created"><div class="ui mini right floated image"><i aria-hidden="true" className="tag icon"></i></div> {task[7] == "" ? this.labels("no tags found") : this.labels(task[7])}</div><hr></hr>

                              <div className="meta" id="created"><div class="ui mini right floated image"><i aria-hidden="true" className="calendar alternate outline icon"></i></div> created {task[4]}</div>


                                      <Modal  id="modals"
                                              open={this.state.modalOpen2}
                                              onClose={this.handleClose2}
                                              basic
                                              size='small'
                                            >
                                              <Header icon='browser' content='Pin Protection' />
                                              <Modal.Content>
                                              <PinInput id="modals"
                                                        length={4}
                                                        focus = {true}
                                                        secret = {true}
                                                        ref={p => (this.pin = p)}
                                                        type="numeric"
                                                        onChange={this.onChange}
                                                        onComplete={(value, index) => {this.write(value, i)}}
                                                      />
                                                      <div id="sendStatus">  </div>

                                              </Modal.Content>
                                              <Modal.Actions>
                                               <button color='black' className="ui button"  onClick={this.onClear}>Clear Pin</button>
                                                <Button color='grey' onClick={this.handleClose2} >
                                                  <Icon name='dont' /> Cant remember
                                                </Button>

                                              </Modal.Actions>
                                            </Modal>
                                            <Modal  id="modals"
                                                    open={this.state.modalOpen3}
                                                    onClose={this.handleClose3}
                                                    basic
                                                    size='small'
                                                  >
                                                    <Header icon='browser' content='Pin Protection' />
                                                    <Modal.Content>
                                                    <PinInput id="modals"
                                                              length={4}
                                                              focus = {true}
                                                              secret = {true}
                                                              ref={p => (this.pin = p)}
                                                              type="numeric"
                                                              onChange={this.onChange}
                                                              onComplete={(value, index) => {this.showPin(value, i)}}
                                                            />
                                                            <div id="sendStatus">  </div>

                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                     <button color='black' className="ui button"  onClick={this.onClear}>Clear Pin</button>
                                                      <Button color='grey' onClick={this.handleClose3} >
                                                        <Icon name='dont' /> Cant remember
                                                      </Button>

                                                    </Modal.Actions>
                                                  </Modal>
                                                  <Modal  id="modals"
                                                          open={this.state.modalOpen4}
                                                          onClose={this.handleClose4}
                                                          basic
                                                          size='small'
                                                        >
                                                          <Header icon='browser' content='Pin Protection' />
                                                          <Modal.Content>
                                                          <PinInput id="modals"
                                                                    length={4}
                                                                    focus = {true}
                                                                    secret = {true}
                                                                    ref={p => (this.pin = p)}
                                                                    type="numeric"
                                                                    onChange={this.onChange}
                                                                    onComplete={(value, index) => {this.showPin(value, i)}}
                                                                  />
                                                                  <div id="sendStatus">  </div>

                                                          </Modal.Content>
                                                          <Modal.Actions>
                                                           <button color='black' className="ui button"  onClick={this.onClear}>Clear Pin</button>
                                                            <Button color='grey' onClick={this.handleClose4}>
                                                              <Icon name='dont' /> Cant remember
                                                            </Button>

                                                          </Modal.Actions>
                                                        </Modal>
                            </div>

                            <div className="extra content">
                            <div className="ui buttons">
                            <button id="editDelete" className="ui button" data-index={i} onClick={task[5] == "" ? this.removeTask  : this.removeTask}>
                              <div className="X" data-index={i}><i aria-hidden="true" className="trash alternate icon"></i> Delete</div>
                            </button>
      { /*
                            <div className="or"></div>

                            <button id="editDelete" className="ui button" data-index={i} onClick={this.removeTask}>
                              <div className="X" data-index={i}><i aria-hidden="true" className="edit icon"></i> Edit Stack</div>
                            </button>*/ }
    </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                      </td>
                  </tr>

                )}
              </div>
          </div>
          <footer className="footer-section">
              <div className="footer-top">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="widget company-intro-widget">
                        <a href="index.html" className="site-logo"></a>
                        <p>The  <img src='./avatar-placeholder2.png' className="avatar" width="25" height="25"alt=""/> application stores user data on Gaia storage system. Transactional metadata is stored on the Blockstack-Blockchain.  </p>
                        <ul className="company-footer-contact-list">

                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="widget course-links-widget">
                        <h5 className="widget-title">decentralized storage</h5>
                        <ul className="courses-link-list">
                          <li><a href="https://docs.blockstack.org/faqs/allfaqs"><i className="fas fa-long-arrow-alt-right"></i>about Blockstack</a></li>
                          <li><a href="https://docs.blockstack.org/storage/overview.html"><i className="fas fa-long-arrow-alt-right"></i>About Gaia</a></li>
                                <li><a href="https://core.blockstack.org/"><i className="fas fa-long-arrow-alt-right"></i>Blockstack Core API</a></li>

                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="widget latest-news-widget">
                        <h5 className="widget-title">lastest news</h5>
                        <ul className="small-post-list">
                          <li>
                            <div className="small-post-item">
                              <a href="#" className="post-date">August 10, 2019</a>
                              <h6 className="small-post-title"><a href="#">End of the close beta</a></h6>
                            </div>
                          </li>
                          <li>
                            <div className="small-post-item">
                              <a href="#" className="post-date">August 23, 2019</a>
                              <h6 className="small-post-title"><a href="#">PasswordStack published</a></h6>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="widget newsletter-widget">
                        <h5 className="widget-title">Donation</h5>
                        <div className="footer-newsletter">
                          <p>You wanna support the project? </p>
                            <p className="small-post-title">BTC 1HYb9vX4aNohcv3urGrBfQzziEwpnAuwkY</p>
                                <p className="small-post-title">ETH 0x32281f0D46DE2B40676a43Ae140606FF16128eD4</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-bottom">
                <div className="container">

                  <div className="row">
                      <div className="col-md-6 col-sm-6 text-sm-left text-center">
                        <span className="copy-right-text">© 2019 <a href="">PasswordStack Team</a> All Rights Reserved.</span>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <ul className="terms-privacy d-flex justify-content-sm-end justify-content-center">
                <a id="public" className="fas fa-phone" href={ `https://core.blockstack.org/v1/search?query=${username}` }><p id="public"> Public Data from {username} </p></a>
                        </ul>

                      </div>
                  </div>
                </div>
              </div>
            </footer>
        </div>
          </div>
        </div>
      </div>
      {/* /#page-content-wrapper */}
    </div>
    {/* /#wrapper */}
    {/* jQuery */}
  </div>
</div>
  );
  }

}

// Made this a default prop (instead of using this.userSession) so a dummy userSession
// can be passed in for testing purposes
Profile.defaultProps = {
  userSession: new UserSession(appConfig)
};

export default Profile

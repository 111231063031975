import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/NavBar.css'
import IdleTimer from 'react-idle-timer'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-146675940-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class NavBar extends Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.onAction = this.onAction.bind(this)
    this.onActive = this.onActive.bind(this)
    this.onIdle = this.onIdle.bind(this)
              this.toggleButton = this.toggleButton.bind(this);
  }
  onActive(e) {
    console.log('user is active', this.idleTimer.getRemainingTime());
  }
  onAction(e) {
    console.log('user is active', this.idleTimer.getRemainingTime());
  }
  onIdle(e) {
    console.log('user is idle', this.idleTimer.isIdle())
  }
  toggleButton() {
    if(document.getElementById("sidebar-wrapper").style.width == "0px") {
      document.getElementById("sidebar-wrapper").style.width = "250px";
      document.getElementById("wrapper").style.paddingLeft = "250px";
      document.getElementById("sidebar-wrapper").style.marginLeft = "-250px";
    } else {
      document.getElementById("sidebar-wrapper").style.width = "0";
      document.getElementById("sidebar-wrapper").style.marginLeft = "0";
      document.getElementById("wrapper").style.paddingLeft = "50px";
      document.getElementById("dashboard").style.width = "100%";
    }
  }
  render() {
    const username = this.props.username
    const user = this.props.user


    return (
      <nav className="navbar navbar-expand-md navbar-dark bg-blue fixed-top">
                <img src={user.avatarUrl() ? user.avatarUrl() : './avatar-placeholder2.png'} className="avatar" width="25" height="25"alt=""/> <Link className="navbar-brand" to="/"> &nbsp; PasswordStack Manager</Link>
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link className="nav-link" to='/'><b id="login"></b> </Link>
          </li>
          <li>
                    <button id="toggle" onClick={this.toggleButton}> ☰ </button>
          </li>
        </ul>

    <button
        onClick={this.props.signOut.bind(this)}
        id="logout" class="ui icon right labeled button">
    <i aria-hidden="true" class="right arrow icon"></i>
    Logout
  </button> <div id="time"></div>
        <div>

                {/* your app here */}
              </div>
      </nav>

    )
  }
}

export default NavBar

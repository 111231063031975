// These functions have been extracted out of Dashboard.js since
// they are the logical functions being tested.
var crypto = require('crypto'),
    algorithm = 'aes-256-ctr',
    password = 'd6F3Efeq';

export function jsonCopy(object) {
  return JSON.parse(JSON.stringify(object))
}

// All three functions take in a state and return a list object of tasks
export function remove(index, state) {
  const tasks = jsonCopy(state.tasks)
  tasks.splice(index, 1) // remove subject at index
  return tasks
}
//Adding Form Values
export function add(app) {

  var hw = encrypt(app.user)
  const task = app.value
  const tasks = jsonCopy(app.tasks)
  tasks.push([task, app.user, app.pass, false, getDate(), app.pin, hw, app.tag, app.url])
  return tasks
}

export function getIndex(index) {
  return index
}


export function check(index, state) {
  const tasks = jsonCopy(state.tasks)
  if(tasks[index][3] == false) {
    tasks[index][3] = true;
  } else if(tasks[index][3] == true){
    tasks[index][3] = false;
  }
  return tasks
}

function getDate() {
var today = new Date();
var dayWeek = today.getDate() < 10 ? "0" + today.getDate(): today.getDate();
var date = dayWeek +'-'+ (today.getMonth()+1) +'-'+ today.getFullYear();
var sec = today.getSeconds() < 10 ? "0" + today.getSeconds(): today.getSeconds();
var min = today.getMinutes() < 10 ? "0" + today.getMinutes(): today.getMinutes();
var hours = today.getHours() < 10 ? "0" + today.getHours(): today.getHours();
var time = hours + ":" + min + ":" + sec;
var dateTime = date+' '+time;
return dateTime
}

function encrypt(text){
  var cipher = crypto.createCipher(algorithm,password)
  var crypted = cipher.update(text,'utf8','hex')
  crypted += cipher.final('hex');
  return crypted;
}

function decrypt(text){
  var decipher = crypto.createDecipher(algorithm,password)
  var dec = decipher.update(text,'hex','utf8')
  dec += decipher.final('utf8');
  return dec;
}

import { AppConfig } from 'blockstack'

export const appConfig = new AppConfig(['store_write', 'publish_data'])

export const TASKS_FILENAME = 'tasks.json'

export const ADDRESS_FILENAME = 'address.json'

export const SECURITY_FILENAME = 'security.json'

export const EXPLORER_URL = 'https://explorer.blockstack.org'

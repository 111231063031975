import React, { Component, Fragment  } from 'react'
import { Button, Checkbox, Form } from 'semantic-ui-react'
import NavBar from './NavBar'
import '../styles/Sidebar.css'
class Users extends Component {
  render() {
    return (
        <>

        </>
      );
  }
}export default Users

import React, { Component } from 'react';
import '../styles/Signin.css'
import 'semantic-ui-css/semantic.min.css';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
} from 'semantic-ui-react'

class Signin extends Component {

  render() {
    const { handleSignIn } = this.props;

    return (
      <div text style={{background: "#27297deb" }}>
        <Menu fixed='top' inverted>
          <Container>
            <Menu.Item as='a' header>
              <Image size='mini' src='/avatar-placeholder2.png' style={{ marginRight: '1.5em' }} />
              PasswordStack Manager
            </Menu.Item>
          </Container>
        </Menu>
    {/* Content */}
        <Container text style={{marginTop: '0em' }}>

        <div class="ui grid">
  <div class="row">
    <div class="eight wide column">
    <div className ="intro">
      <div className="panel-landing" id="section-1">
        <h1 className="landing-heading">PasswordStack Manager</h1>
        <p id="subtitle">Your decentralized Password Vault </p>
                <p id="subtitle"> No matter which operating system you use, PasswordStack is a client-side web-application solution. Only you decide when and how to access your data</p>
        <button
          className="btn btn-primary btn-lg"
          id="signin-button"
          onClick={ handleSignIn.bind(this) }
        >
          Login with Blockstack
        </button>
      </div>
    </div>
    </div>
    <div class="eight wide column">
            <img class="original-image" src="stake.png"/>
    </div>
  </div>
  <div class="row">
    <div class="eight wide column">
      <img class="original-image" src="image2.png"/>
    </div>
    <div class="eight wide column">
    <h1 className="landing-heading">Dual Stack Security</h1>
    <p id="subtitle">Local storage? Central server? <br></br>No, PasswordStack stores your encrypted<i id="personal"> user data </i> on Gaia storage system. <i id="trans"> Transactional metadata </i> stored on the Blockstack-Blockchain.</p>
    <h1 className="landing-heading">Pin-Protection</h1>
    <p id="subtitle">In addition to end-to-end encryption, for maximum security, each login pair can be protected with a four-digit pin.</p>
    </div>
  </div>
  <div class="row">
    <div class="eight wide column">
        <h1 className="landing-heading">Price? For Free ! </h1>
        <p id="subtitle">Limit? Only the sky! PasswordStack Manager is completely free and has no limit of vaults. <i><br/> P.S. there is enough space for all yours vaults, </i> <br/> stack as many passwords as you like !</p>
    </div>
    <div class="eight wide column">
          <img class="original-image" src="stake2.png"/>
    </div>
  </div>
</div>
        </Container>

        <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
          <Container textAlign='center'>
            <Grid divided inverted stackable>
              <Grid.Column width={3}>
              <Header inverted as='h4' content='Stack Level' />
  <p id="dont">The  <img src='./avatar-placeholder2.png' className="avatar" width="25" height="25"alt=""/> application stores user data on Gaia storage system. Transactional metadata is stored on the Blockstack-Blockchain.  </p>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as='h4' content='Decentralized Storage' />
                <List link inverted>
                  <List.Item href="https://docs.blockstack.org/storage/overview.html" as='a'>About Gaia</List.Item>
                  <List.Item href="https://docs.blockstack.org/" as='a'>About Blockstack</List.Item>
                  <List.Item href="https://core.blockstack.org/" as='a'>About Blockstack Core API</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as='h4' content='Contact' />
                <List.Item href="https://discord.gg/rhGceje" as='a'> <i className="discord icon"/></List.Item>
              <List.Item href="mailto:support@passwordstack.de" as='a'> support@passwordstack.de</List.Item>
              </Grid.Column>
              <Grid.Column width={7}>
                <Header inverted as='h4' content='You wanna support the project'> Send <i className="like icon"/> with a Donation </Header>
                <p id="dont">
                  BTC 1HYb9vX4aNohcv3urGrBfQzziEwpnAuwkY
                </p>
                <p id="dont">
                  ETH 0x1B5e736F03e10efC3D56796c3E894dd192332cAc
                </p>
                <p id="dont">
                  ETC 0x3b6fe0eEe5F82BBf57f5A93cB1A8D1918d64fd07

                </p>

              </Grid.Column>

            </Grid>
            <Divider inverted section />
            <Image centered size='tiny' src='/avatar-placeholder2.png' /> © 2019 PasswordStack Team All Rights Reserved
            <br></br>    <br></br>
  <a id="producthunt" target="_blank" href="https://www.producthunt.com/posts/passwordstack?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-passwordstack"><Image centered size='medium' src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=165760&theme=light" /></a>
          </Container>

        </Segment>
      </div>
    );
  }
}

export default Signin

import React from 'react'
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import App from './components/App.js';
import Signin from './components/Signin.js';
import User from './components/User.js';
import 'bootstrap/dist/css/bootstrap.css'
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
const hist = createBrowserHistory();
ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/settings" render={props => <User {...props} />} />
      <Route path="/" render={props => <App {...props} />} />
    </Switch>
  </Router>, document.getElementById('app'));
